/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React from 'react';
import cookiee from 'cookie';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { getComponentDisplayName } from '../utils/helpers';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import Head from '@/componentWebs/headLayout';
import { languages } from '@/utils/utils';

const Header = dynamic(() => import('@/componentWebs/downloadvideo/Header'), {
  ssr: true,
  loading: () => null,
});
const Footer = dynamic(() => import('@/componentWebs/downloadvideo/Footer'), {
  ssr: true,
  loading: () => null,
});
const { publicRuntimeConfig } = getConfig();
// lazy load

export default ComposedComponent => {
  class WebLayout extends React.Component {
    static displayName = `WebLayout(${getComponentDisplayName(ComposedComponent)})`;

    static async getInitialProps(context) {
      const { req, asPath, pathname, query, store, dispatch } = context;
      let cookies;
      let host = '';
      let dataSite = {};
      if (req) {
        // console.log('headers', req.headers);
        // console.log('SITE_NAME', publicRuntimeConfig.SITE_NAME);
        // eslint-disable-next-line prefer-destructuring
        cookies = req.cookies;
        host =
          req.headers.host === 'localhost:8834' ? publicRuntimeConfig.SITE_NAME : req.headers.host;
      } else {
        const documentCookie = document.cookie;
        cookies = cookiee.parse(documentCookie);
        host =
          window.location.host === 'localhost:8834'
            ? publicRuntimeConfig.SITE_NAME
            : window.location.host;
      }
      if (host.indexOf('www') === 0) {
        host = host.slice(4);
      }
      await dispatch({
        type: 'webs/fetchSiteUrl',
        payload: { url: host },
        callback: result => {
          dataSite = result;
        },
      });

      const SITEID_WEB = dataSite?.id;
      const languagesDefault =
        dataSite?.siteProfiles?.find(i => i?.isDefault === 1)?.languagesId || '1';
      const locale =
        (query?.lang && languages?.find(i => i?.languagesCode === query?.lang)?.languagesCode) ||
        cookies?.locale ||
        languages?.find(i => i?.id === languagesDefault)?.languagesCode ||
        'vi';
      const languagesId =
        dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)?.languagesId ||
        '1';

      if (SITEID_WEB) {
        const queryMenus = await {
          filter: {
            status: '1',
            menuPositionsId: `${publicRuntimeConfig.MENU__POSITIONID_TOP},${
              publicRuntimeConfig.MENU__POSITIONID_BOTTOM
            }`,
            languagesId,
            sitesId: SITEID_WEB,
          },
          sort: ['orderBy', 'ASC'],
        };
        await dispatch({
          type: 'webs/fetchMenus',
          payload: queryMenus,
        });
      }
      const {
        webs: { dataMenu },
      } = await store.getState();

      const menus = dataMenu?.list || [];
      const menuHeader =
        (menus?.length > 0 &&
          menus.filter(
            item =>
              Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_TOP)
          )) ||
        [];
      const menuFooter =
        (menus?.length > 0 &&
          menus.filter(
            item =>
              Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_BOTTOM)
          )) ||
        [];
      const dataSiteNew = {
        ...dataSite,
        icon: dataSite?.icon?.file && {
          ...dataSite?.icon,
          file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            dataSite?.icon?.file
          }`,
        },
        logo: dataSite?.logo?.map(item2 => ({
          ...item2,
          file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            item2?.file
          }`,
        })),
        siteProfiles: dataSite?.siteProfiles?.map(item2 => ({
          ...item2,
          languages: item2?.languages?.id && {
            ...item2?.languages,
            icon: item2?.languages?.icon?.file && {
              ...item2?.languages?.icon,
              file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                item2?.languages?.icon?.file
              }`,
            },
          },
        })),
      };
      // console.log(res);
      return {
        ...(ComposedComponent.getInitialProps
          ? await ComposedComponent.getInitialProps(context)
          : {}),
        query,
        cookies,
        asPath,
        pathname,
        dataSite: dataSiteNew,
        locale,
        languagesId,
        menuHeader,
        menuFooter,
        host,
      };
    }

    render() {
      return (
        <React.Fragment>
          <Head>
            <link
              rel="preload"
              href="/downloadvideo/css/inline.css"
              as="style"
              onLoad="this.onload=null;this.rel='stylesheet'"
            />
            <link rel="stylesheet" href="/downloadvideo/css/styleDown.css" />
            <link
              rel="preload"
              href="/downloadvideo/css/ckeditor.css"
              as="style"
              onLoad="this.onload=null;this.rel='stylesheet'"
            />
            <link
              rel="preload"
              href="/downloadvideo/css/tableDownLoad.css"
              as="style"
              onLoad="this.onload=null;this.rel='stylesheet'"
            />
            <noscript>
              <link rel="stylesheet" href="/downloadvideo/css/inline.css" />
              <link rel="stylesheet" href="/downloadvideo/css/ckeditor.css" />
              <link rel="stylesheet" href="/downloadvideo/css/tableDownLoad.css" />
            </noscript>
          </Head>
          <Header {...this.props} />
          <ComposedComponent {...this.props} />
          <Footer {...this.props} />
        </React.Fragment>
      );
    }
  }

  return injectIntl(WebLayout);
};
