/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable compat/compat */
/* eslint-disable no-new */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, useRef } from 'react';
// import { getResponsiveImage } from '@/componentWebs/ImageNew';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { isValidURL, transformUrl, FileMimeType, formatSeconds } from '@/utils/utils';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
import encode from '@/utils/encode';
import _ from 'lodash';

const arr = [
  {
    id: '1',
    lable: 'Video',
    icon: () => (
      <svg
        style={{ width: 16, height: 'auto', margin: '-5px 5px 0 0' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
        />
      </svg>
    ),
  },
  {
    id: '2',
    lable: 'Audio',
    icon: () => (
      <svg
        style={{ width: 16, height: 'auto', margin: '-5px 5px 0 0' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"
        />
      </svg>
    ),
  },
  {
    id: '3',
    lable: 'Other',
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-file-plus"
        viewBox="0 0 16 16"
      >
        <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z" />
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
      </svg>
    ),
  },
];

function SearchDownload(props) {
  const { dispatch, url, intl, dataCategory, dataCategoryInfo, dataSite, locale } = props;
  const [visibleHuongdan, setVisibleHuongdan] = useState(false);
  const [error, setError] = useState('');
  const [tabActive, setTabActive] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState([]);
  const [dataModal, setDataModal] = useState(false);
  const [link, setLink] = useState(url || '');
  const [listVideo, setListVideo] = useState({});
  const [resultError, setResultError] = useState(false);
  const [progress, setProgress] = useState([]);
  const [linkdie, setLinkdie] = useState([]);
  const intervals = useRef([]);

  const handleInputChange = event => {
    setLink(event.target.value);
    setError('');
  };

  const handleSubmit = async (event, refetch) => {
    event.preventDefault();
    setIsDownloading([]);
    if (link) {
      if (isValidURL(link)) {
        setResultError(false);
        setError('');
        setIsLoading(true);
        if (link.includes('facebook.com') || link.includes('fb.com') || link.includes('fb.watch')) {
          let urlNew = link;
          urlNew = urlNew.replace('m.', '');
          urlNew = transformUrl(urlNew);
          dispatch({
            type: 'download/fetchByLinkfacebook',
            payload: {
              link: urlNew,
              orginLink: link,
            },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj,
                    music: [],
                    videos: [
                      {
                        idLoading: Math.random(),
                        id: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'Video HD',
                        url: obj?.hdUrl,
                      },
                      {
                        idLoading: Math.random(),
                        id: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'Video SD',
                        url: obj?.sdUrl,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('youtube.com') || link.includes('youtu.be')) {
          dispatch({
            type: 'download/fetchByLinkyoutube',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                if (!res?.result?.video?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const musicSource =
                  res?.result?.video?.music?.map(i => ({ ...i, idLoading: Math.random() })) || [];
                const videoSource =
                  res?.result?.video?.videos?.filter(video => video?.qualityLabel) || [];
                const result = [];
                const uniqueQualities = new Set();
                // eslint-disable-next-line no-unused-expressions
                videoSource?.forEach((i, index) => {
                  if (
                    !i.hasAudio &&
                    !uniqueQualities.has(i.qualityLabel) &&
                    musicSource?.length > 0 &&
                    videoSource?.filter(i2 => i?.qualityLabel === i2?.qualityLabel && !i2.hasAudio)
                      ?.length > 0
                  ) {
                    result.push({
                      idLoading: Math.random(),
                      id: index,
                      hasAudio: true,
                      isMerge: true,
                      'content-type': i?.['content-type'],
                      qualityLabel: i?.qualityLabel,
                      music: musicSource?.map(i2 => ({
                        url: i2?.url,
                      })),
                      video: videoSource
                        ?.filter(i2 => i?.qualityLabel === i2?.qualityLabel && !i2.hasAudio)
                        ?.map(i2 => ({
                          url: i2?.url,
                        })),
                    });
                    uniqueQualities.add(i.qualityLabel);
                  }
                  result.push({ ...i, idLoading: Math.random() });
                });
                console.log('videoSource', videoSource);

                const uniqueQualityLabels = _(result)
                  .orderBy(['isMerge'], ['asc']) // Sắp xếp ưu tiên `hasAudio = true`
                  .uniqBy('qualityLabel') // Lọc duy nhất theo `qualityLabel`
                  .value();
                const remainingItems = _.orderBy(
                  _.differenceWith(result, uniqueQualityLabels, _.isEqual),
                  ['hasAudio', 'qualityLabel'],
                  ['desc', 'desc']
                );
                const convert = {
                  ...res?.result,
                  video: {
                    ...res?.result?.video,
                    videos: [],
                    remainingItems,
                    uniqueQualityLabels,
                    music: musicSource,
                  },
                };
                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('tiktok.com')) {
          dispatch({
            type: 'download/fetchByLinktiktok',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result?.[0];
                if (!obj?.video?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [{ idLoading: Math.random(), url: obj?.video?.music }]?.filter(
                      i => i?.url
                    ),
                    videos: [
                      {
                        idLoading: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'no Logo',
                        url: obj?.video?.url,
                      },
                      {
                        idLoading: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'has Logo',
                        url: obj?.video?.urlWatermark,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                // console.log(convert);

                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('douyin.com')) {
          dispatch({
            type: 'download/fetchByLinkdouyin',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result?.[0];
                if (!obj?.video?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [{ idLoading: Math.random(), url: obj?.video?.music }]?.filter(
                      i => i?.url
                    ),
                    videos: [
                      {
                        idLoading: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'no Logo',
                        url: obj?.video?.url,
                      },
                      {
                        idLoading: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'has Logo',
                        url: obj?.video?.urlWatermark,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('pinterest.com') || link.includes('pin.it')) {
          dispatch({
            type: 'download/fetchByLinkprintest',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [],
                    videos: [
                      {
                        idLoading: Math.random(),
                        hasAudio: true,
                        qualityLabel: 'Video',
                        url: obj?.video?.url,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('instagram.com')) {
          dispatch({
            type: 'download/fetchByLinkinstagram',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  id: obj?.id,
                  video: {
                    id: obj?.id,
                    content: obj?.title || obj?.content,
                    cover: obj?.thumbnail,
                    music: [],
                    videos: obj?.videos?.map(i => ({
                      ...i,
                      qualityLabel: i?.quality,
                      hasAudio: true,
                      idLoading: Math.random(),
                    })),
                  },
                };

                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else if (
          link.includes('x.com') ||
          link.includes('twiter.com') ||
          link.includes('twitter.com')
        ) {
          dispatch({
            type: 'download/fetchByLinktwiter',
            payload: { link },
            callback: res => {
              if (res?.success) {
                setIsLoading(false);
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  id: obj?.id,
                  video: {
                    id: obj?.id,
                    content: obj?.title || obj?.content,
                    cover: obj?.thumbnail,
                    music: [],
                    videos: obj?.videos?.map(i => ({
                      ...i,
                      idLoading: Math.random(),
                      qualityLabel: i?.quality,
                      hasAudio: true,
                    })),
                  },
                };

                setListVideo(convert);
              } else if (!res?.success && refetch) {
                handleSubmit(event, false);
              } else {
                setIsLoading(false);
                setListVideo({});
                setResultError(true);
              }
            },
          });
        } else {
          setIsLoading(false);
          setListVideo({});
          setResultError(true);
        }
      } else {
        setError(intl.formatMessage({ id: 'errorFormat' }));
      }
    }
  };

  const handleClear = () => {
    setLink('');
    setError('');
  };
  const handleClick = debounce(() => {
    setVisibleHuongdan(pre => !pre);
  }, 200);

  function hexToRgba(hex, alpha) {
    const color = hex?.startsWith('#') ? hex : '#000000';
    // Chuyển mã màu hex sang giá trị RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Trả về giá trị RGBA
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const checkDownloadProgress = async item => {
    const res = await fetch(`/api/progress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ taskId: item?.randomNumber }),
    });
    const data = await res.json();
    // console.log('datadata', data);

    if (data?.progress && data?.progress !== 0 && data?.progress !== 100 && data?.progress !== -1) {
      setProgress(
        pre =>
          pre?.map(i => ({
            ...i,
            progress:
              item?.randomNumber === i?.id
                ? Math.max(Number(data.progress), i?.progress)
                : i?.progress,
          })) || []
      );
      setDataModal(pre => ({
        ...pre,
        progress:
          pre?.id === item?.randomNumber
            ? Math.max(Number(data.progress), pre?.progress)
            : pre?.progress,
      }));
    }
    // console.log('item', item);
    // console.log('progress', data.progress);
    // console.log('intervals', intervals.current);
    if (data.progress === 100) {
      const processToStop = intervals?.current?.find(
        process => process.randomNumber === item?.randomNumber
      );
      if (processToStop) {
        clearInterval(processToStop.intervalId);
        intervals.current =
          intervals.current?.filter(i => i?.randomNumber !== processToStop?.randomNumber) || [];
        setProgress(pre => pre?.filter(i => i?.id !== item?.randomNumber) || []);

        setDataModal(pre => ({
          ...pre,
          progress: pre?.id === item?.randomNumber ? 100 : pre?.progress,
        }));
        setTimeout(() => {
          setIsDownloading(pre => pre?.filter(i => i !== item?.randomNumber) || []);
          const downloadLink = document.createElement('a');
          downloadLink.href = item?.url;
          downloadLink.download = `downloadvideoVN${listVideo?.video?.content &&
            `_${encode(listVideo?.video?.content) || ''}`}_${
            item?.type === '1' ? item?.qualityLabel : 'audio'
          }${item?.extension}`;
          document.body.appendChild(downloadLink);
          downloadLink.click(); // Kích hoạt tải file
          document.body.removeChild(downloadLink); // Xóa thẻ a sau khi tải xong
        }, 500);
      }
    }
    if (data.progress === -1) {
      // eslint-disable-next-line no-alert

      const processToStop = intervals?.current?.find(
        process => process.randomNumber === item?.randomNumber
      );
      if (processToStop) {
        clearInterval(processToStop.intervalId);
        intervals.current =
          intervals.current?.filter(i => i?.randomNumber !== processToStop?.randomNumber) || [];
      }
      setDataModal(pre => ({
        ...pre,
        err: pre?.id === item?.randomNumber && true,
      }));
      alert(intl.formatMessage({ id: 'downloadFalse' }));
      // setLinkdie(pre => [...pre, item?.url]);
    }
  };

  const downloadVideo = async item => {
    const filename = `downloadvideoVN${listVideo?.video?.content &&
      `_${encode(listVideo?.video?.content) || ''}`}_${
      item?.type === '1' ? item?.qualityLabel : 'audio'
    }`;
    const exists = progress.some(i => i.name === filename);
    if (exists) {
      alert(intl.formatMessage({ id: 'warningProgress' }));
      return;
    }
    setIsDownloading(per => [...per, item?.idLoading]);
    const randomNumber = item?.idLoading;
    const data = {
      ...item,
      randomNumber,
    };
    // checkDownloadProgress(data);
    const response = await fetch(`/api/mergeMedia`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (result?.status === false) {
      setIsDownloading(pre => pre?.filter(i => i !== randomNumber) || []);
      alert(intl.formatMessage({ id: 'downloadFalse' }));
      return;
    }

    // return;

    setProgress(pre => [
      ...pre,
      {
        id: randomNumber,
        name: filename,
        progress: 0,
        url: result?.downloadUrl,
        extension: result?.extension,
      },
    ]);
    setTimeout(() => {
      setDataModal({
        id: randomNumber,
        name: filename,
        progress: 0,
        url: result?.downloadUrl,
        extension: result?.extension,
      });
    }, 100);

    const intervalId = setInterval(() => {
      checkDownloadProgress({ ...data, url: result?.downloadUrl, extension: result?.extension });
    }, 3000);
    intervals.current = [...intervals.current, { intervalId, randomNumber }];
  };

  const checkStatus = async item => {
    setIsDownloading(per => [...per, item?.idLoading]);
    const filename = `downloadvideoVN${listVideo?.video?.content &&
      `_${encode(listVideo?.video?.content) || ''}`}_${
      item?.type === '1' ? item?.qualityLabel : 'audio'
    }`;
    if (item?.url?.includes('source=youtube')) {
      setIsDownloading(pre => pre?.filter(i => i !== item?.idLoading) || []);
      const linkNew = document.createElement('a');
      linkNew.href = item?.url;
      linkNew.target = '_blank'; // Mở tab mới
      linkNew.rel = 'noopener noreferrer'; // Tăng cường bảo mật
      linkNew.download = filename;
      document.body.appendChild(linkNew);
      linkNew.click();
      document.body.removeChild(linkNew);
      return;
    }

    setProgress(pre => [
      ...pre,
      {
        id: item?.idLoading,
        name: filename,
        progress: 5,
        url: item?.url,
      },
    ]);
    setDataModal({
      id: item?.idLoading,
      name: filename,
      progress: 5,
    });
    const queryString = new URLSearchParams({ ...item, name: filename }).toString();
    const linkNew = document.createElement('a');
    linkNew.href = `/api/mergeMedia?${queryString}`; // URL API trả về file
    linkNew.setAttribute('download', ''); // Trình duyệt sẽ lấy filename từ header
    document.body.appendChild(linkNew);
    linkNew.click();
    document.body.removeChild(linkNew);
    // const response = await fetch('/api/mergeMedia');

    // const intervalId = setInterval(() => {
    //   const randomInt2 = Math.floor(Math.random() * (10 - 5 + 1)) + 5;
    //   setProgress(
    //     pre =>
    //       pre?.map(i => ({
    //         ...i,
    //         progress:
    //           item?.idLoading === i?.id
    //             ? Math.min(95, Number(i?.progress + randomInt2))
    //             : i?.progress,
    //       })) || []
    //   );
    //   setDataModal(pre => ({
    //     ...pre,
    //     progress:
    //       pre?.id === item?.idLoading
    //         ? Math.min(95, Number(pre?.progress + randomInt2))
    //         : pre?.progress,
    //   }));
    // }, 500);
    // if (!response.ok) {
    //   setIsDownloading(pre => pre?.filter(i => i !== item?.idLoading) || []);
    //   setProgress(pre => pre?.filter(i => i?.id !== item?.idLoading) || []);
    //   clearInterval(intervalId);
    //   setDataModal(pre => ({
    //     ...pre,
    //     err: pre?.id === item?.idLoading && true,
    //   }));
    //   alert(intl.formatMessage({ id: 'downloadFalse' }));
    //   setLinkdie(pre => [...pre, item?.url]);
    //   return;
    // }
    // const reader = response.body.getReader();
    // const stream = new ReadableStream({
    //   start(controller) {
    //     function push() {
    //       reader.read().then(({ done, value }) => {
    //         if (done) {
    //           controller.close();
    //           return;
    //         }
    //         controller.enqueue(value);
    //         push();
    //       });
    //     }
    //     push();
    //   },
    // });

    // const newResponse = new Response(stream);
    // const blob = await newResponse.blob();
    // const blobUrl = URL.createObjectURL(blob);

    // const linkNew = document.createElement('a');
    // linkNew.href = blobUrl;
    // linkNew.download = 'download.mp4';
    // document.body.appendChild(linkNew);
    // linkNew.click();
    // document.body.removeChild(linkNew);
    // URL.revokeObjectURL(blobUrl);

    setIsDownloading(pre => pre?.filter(i => i !== item?.idLoading) || []);
    // clearInterval(intervalId);
    setProgress(pre => pre?.filter(i => i?.id !== item?.idLoading) || []);
    setDataModal(pre => ({
      ...pre,
      progress: pre?.id === item?.idLoading ? 100 : pre?.progress,
    }));
    setTimeout(() => {
      setDataModal();
    }, 200);
  };

  const downloadImage = async imageUrl => {
    setIsDownloading(per => [...per, imageUrl]);
    const response = await fetch(`/api/downloadImage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: imageUrl }),
    });
    if (!response.ok) {
      setIsDownloading(pre => pre?.filter(i => i !== imageUrl) || []);
      console.error('Failed to download image');
      return;
    }
    const filename = `downloadvideoVN${(listVideo?.video?.content &&
      `_${encode(listVideo?.video?.content)}`) ||
      ''}_image.jpg`;

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const linkNew = document.createElement('a');
    linkNew.href = blobUrl;
    linkNew.download = filename;
    linkNew.click();
    setIsDownloading(pre => pre?.filter(i => i !== imageUrl) || []);

    URL.revokeObjectURL(blobUrl);
  };

  return (
    <div className="downloader-block">
      <div className="open-link-form-all-block open-link-form-old">
        <div className="main-form-box">
          <div className="main-form-box-left">
            <div id="link-form-old-cont">
              <div className="downloader-2 downloader-2-part1">
                <div
                  className="search-panel__container container_arrow_new"
                  style={{
                    background:
                      dataCategoryInfo?.description &&
                      hexToRgba(dataCategoryInfo?.description, 0.05),
                  }}
                >
                  <h1 className="search-panel__mobile__header">
                    {dataCategoryInfo?.image?.file && (
                      <img
                        className="lazyload"
                        width={40}
                        height={40}
                        src={getResponsiveImage(dataCategoryInfo?.image?.file, [50, 50, 50])}
                        alt={
                          dataCategoryInfo?.categoriesName ||
                          dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)
                            ?.siteProfilesName
                        }
                      />
                    )}
                    {dataCategoryInfo?.categoriesName ||
                      dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)
                        ?.siteProfilesName}
                  </h1>
                </div>
                {/* main form */}
                <form
                  onSubmit={e => handleSubmit(e, true)}
                  className="sf-form link-form"
                  target="sf_frame"
                  id="sf_form"
                >
                  <div className="form-block">
                    <div className="l-box">
                      <div className="tarea-wrap">
                        <input
                          key={listVideo?.video?.id}
                          type="text"
                          value={link}
                          onChange={handleInputChange}
                          placeholder={intl.formatMessage({ id: 'placeholderSearch' })}
                        />
                        <div
                          onClick={handleClear}
                          className="clear-btn"
                          style={{ display: (link && 'inline') || 'none' }}
                        />
                      </div>
                    </div>
                    <div className="r-box">
                      <button
                        type="submit"
                        name="sf_submit"
                        id="sf_submit"
                        className="submit"
                        disabled={isLoading}
                        style={{
                          background: dataCategoryInfo?.description,
                          opacity: isLoading ? 0.5 : 1,
                        }}
                      >
                        <img
                          className="lazyload"
                          src={`/downloadvideo/images/${isLoading ? 'loadding.gif' : 'play.png'}`}
                          alt="downicon"
                          width="20px"
                          height="20px"
                          style={{ marginRight: 5 }}
                        />
                        {intl.formatMessage({ id: 'download' })}
                      </button>
                    </div>
                  </div>
                  {error && <p style={{ color: 'red', fontSize: 14 }}>{error}</p>}
                </form>
              </div>
              <div className="main-form__wrapper">
                <div
                  className="main-form__watch main-form__watch_show"
                  style={{ display: visibleHuongdan && 'none' }}
                  onClick={event => {
                    event.stopPropagation();
                    handleClick();
                  }}
                >
                  <img className="lazyload" src="/downloadvideo/images/btnPlay.svg" alt="" />
                  <button className="main-form__button" type="button">
                    {intl.formatMessage({ id: 'howtodownload' })}
                  </button>
                  <span>{intl.formatMessage({ id: 'seeInstructions' })}</span>
                </div>
                {visibleHuongdan && (
                  <div className="main-form__video" style={{ display: 'block' }}>
                    <video
                      width="auto"
                      height="auto"
                      playsInline
                      loop
                      muted
                      controls
                      className="search-result-video"
                    >
                      <source
                        src={getResponsiveImage(
                          dataCategory?.slice(0, 1)?.[0]?.image?.file,
                          'video'
                        )}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="downloader-2 downloader-2-part2">
        {isLoading && (
          <div id="sf_indicator_box" style={{}}>
            <div id="sf_indicator">
              <p>{intl.formatMessage({ id: 'loadingSearch' })}</p>
              <img className="lazyload" src="/downloadvideo/images/busy.gif" alt="..." />
            </div>
          </div>
        )}
        {!resultError && listVideo?.video?.id && (
          <div id="sf_result">
            <div className="media-result">
              <div className="result-box video popup-in-output" data-hid={101} style={{}}>
                <div className="thumb-box thumb-272 ">
                  <a href="#" className="clip" data-player-id="video_player_1724137476433_8969">
                    <img
                      src={listVideo?.video?.cover}
                      className="thumb lazyload"
                      alt={listVideo?.video?.content || ''}
                    />
                    <span className="play-btn" />
                  </a>
                </div>
                <div className="info-box">
                  <div className="meta">
                    <div className="row title" title={listVideo?.video?.content}>
                      {listVideo?.video?.content}
                    </div>
                    <div className="row subtitle">{listVideo?.author?.nickname || ''}</div>
                    {listVideo?.video?.lengthSeconds && (
                      <div className="row duration" title="">
                        {formatSeconds(listVideo?.video?.lengthSeconds)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="result-box tableVideo">
                <ul className="nav nav-tabs justify-content-start" id="selectTab" role="tablist">
                  {arr?.map(i => (
                    <li
                      className={`nav-item p-0 ${(tabActive === i?.id && 'active') || ''}`}
                      key={i?.id}
                    >
                      {' '}
                      <a className="nav-link" onClick={() => setTabActive(i?.id)}>
                        {i?.icon()}
                        {i?.lable}
                      </a>{' '}
                    </li>
                  ))}
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active in" id="mp3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>File type</th>
                          <th>Format</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tabActive === '1' &&
                          [
                            ...(listVideo?.video?.uniqueQualityLabels?.filter(
                              item => !linkdie.includes(item.url)
                            ) || []),
                            ...(listVideo?.video?.videos?.filter(
                              item => (item?.url && !linkdie.includes(item.url)) || !item?.url
                            ) || []),
                            ...(listVideo?.video?.remainingItems?.filter(
                              item => (item?.url && !linkdie.includes(item.url)) || !item?.url
                            ) || []),
                          ]?.map(i => (
                            <tr>
                              <td>
                                {!i?.hasAudio && (
                                  <img
                                    className="lazyload"
                                    src="/downloadvideo/images/no_audio_red.png"
                                    alt=""
                                  />
                                )}{' '}
                                {i?.qualityLabel}
                              </td>
                              <td> {FileMimeType[i['content-type']] || 'mp4'}</td>
                              <td className="txt-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (isDownloading?.includes(i?.idLoading)) {
                                      setDataModal(progress?.find(i2 => i2?.id === i?.idLoading));
                                      return;
                                    }
                                    if (i?.isMerge) {
                                      downloadVideo({ ...i, type: '1' });
                                    } else {
                                      checkStatus({ ...i, type: '1' });
                                    }
                                  }}
                                  style={{
                                    opacity: isDownloading?.includes(i?.idLoading) ? 0.5 : 1,
                                  }}
                                  className="btn-success y2link-download custom"
                                >
                                  {isDownloading?.includes(i?.idLoading) ? (
                                    <img
                                      className="lazyload"
                                      src="/downloadvideo/images/loadding.gif"
                                      alt=""
                                      width="15px"
                                      height="15px"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      fill="#fff"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z" />
                                    </svg>
                                  )}
                                  &nbsp;{intl.formatMessage({ id: 'download' })}
                                </button>
                              </td>
                            </tr>
                          ))}
                        {tabActive === '2' &&
                          listVideo?.video?.music?.map(i => (
                            <tr>
                              <td> {intl.formatMessage({ id: 'audio' })}</td>
                              <td> {FileMimeType[i['content-type']] || 'mp3'}</td>
                              <td className="txt-center">
                                <button
                                  type="button"
                                  onClick={() => checkStatus(i)}
                                  className="btn-success y2link-download custom"
                                  style={{
                                    opacity: isDownloading?.includes(i?.idLoading) ? 0.5 : 1,
                                  }}
                                >
                                  {isDownloading?.includes(i?.idLoading) ? (
                                    <img
                                      className="lazyload"
                                      src="/downloadvideo/images/loadding.gif"
                                      alt=""
                                      width="15px"
                                      height="15px"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      fill="#fff"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z" />
                                    </svg>
                                  )}
                                  &nbsp;{intl.formatMessage({ id: 'download' })}
                                </button>
                              </td>
                            </tr>
                          ))}
                        {tabActive === '3' && listVideo?.video?.cover && (
                          <tr>
                            <td> {intl.formatMessage({ id: 'thumbnail' })}</td>
                            <td>Image</td>
                            <td className="txt-center">
                              <button
                                type="button"
                                onClick={() => downloadImage(listVideo?.video?.cover)}
                                className="btn-success y2link-download custom"
                                style={{
                                  opacity: isDownloading?.includes(listVideo?.video?.cover)
                                    ? 0.5
                                    : 1,
                                }}
                              >
                                {isDownloading?.includes(listVideo?.video?.cover) ? (
                                  <img
                                    className="lazyload"
                                    src="/downloadvideo/images/loadding.gif"
                                    alt=""
                                    width="15px"
                                    height="15px"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15px"
                                    height="15px"
                                    fill="#fff"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z" />
                                  </svg>
                                )}
                                &nbsp;{intl.formatMessage({ id: 'download' })}
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {resultError && (
          <div id="sf_result">
            <div className="result-box simple center result-failure" data-hid="other" style={{}}>
              {intl.formatMessage({ id: 'errorLink' })}
            </div>
          </div>
        )}
      </div>
      {!!dataModal?.id && (
        <div className="mmodal mfade in" id="progress">
          <div className="modal-backdrop mfade" />
          <div className="modal-dialog" role="document" style={{ zIndex: 999999 }}>
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="model_head">{intl.formatMessage({ id: 'progress' })}</h3>
                <button
                  type="button"
                  className="close btn-download-close"
                  onClick={() => setDataModal({})}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center' }}>
                <div className="prog_wrap">
                  <div id="process-waiting">
                    <img
                      alt="loadingdownload"
                      src="/downloadvideo/images/loading.gif"
                      className="m-t loading_img"
                    />
                    <p className="p-t">
                      <b>{intl.formatMessage({ id: 'waiting' })}</b>
                    </p>
                  </div>
                  <div className="progress_wrap">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${dataModal?.progress}%`,
                        backgroundColor: 'rgb(92, 184, 92)',
                      }}
                    >
                      <span
                        className="progress-status"
                        style={{ backgroundColor: dataModal?.err ? 'red' : 'rgb(92, 184, 92)' }}
                      >
                        {dataModal?.progress}%
                      </span>
                    </div>
                  </div>
                </div>
                <div id="share-place-download">
                  <p className="text-left">
                    {' '}
                    {intl.formatMessage({ id: 'thankyouNote' })}
                    <strong>{intl.formatMessage({ id: 'thankyou' })}</strong>{' '}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default btn-download-close"
                  onClick={() => setDataModal({})}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default connect()(SearchDownload);
